<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="500" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">
              {{ $route.name == "show-user" ? user.fullName : "ملفي الشخصي" }}
            </h3>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-layout wrap>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الاسم الكامل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ user.fullName }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الإيميل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ user.email }}</strong>
              </v-flex>

              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموبايل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ user.phoneNumber }}</strong>
              </v-flex>

              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الدور</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{
                  $route.name == "show-user" ? selectRole : getRole
                }}</strong>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="background:#5867dd;color:white;margin-left:5px;"
              @click="editPassword"
            >
              <b>تعديل كلمة السر</b>
            </v-btn>
            <v-btn
              style="background:#5867dd;color:white;margin-left:5px;"
              @click="
                $router.push({
                  name: 'edit-user',
                  params: { userId: user.id }
                })
              "
            >
              <b>تعديل</b>
            </v-btn>
            <v-btn @click="$router.go(-1)" style="background:gray;" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
//  import Vue from "vue";
export default {
  data() {
    return {
      role: "",
      selectRole: "",
      user: {
        password: "",
        fullName: "",
        userName: "",
        email: "",
        phoneNumber: ""
      }
    };
  },
  watch: {
    $route() {
      this.getUserInfo();
    }
  },
  computed: {
    getRole() {
      switch (this.role) {
        case "Salesman":
          return "مندوب مبيعات";
        case "Supervisor":
          return "مشرف";
        case "Director":
          return "مدير إدارة";
        case "General Manager Of Dept":
          return "رئيس قسم";
        case "CEO":
          return "المدير العام";
        case "Admin":
          return "أدمن";
        default:
          return "-";
      }
    }
  },
  methods: {
    editPassword() {
      if (this.$route.name == "show-user")
        this.$router.push({
          name: "edit-user-password",
          params: { Id: this.user.id }
        });
      else this.$router.push({ name: "edit-password" });
    },
    async getUser(id) {
      await this.ApiService.get("Auth/GetUser?id=" + id)
        .then(res => {
          this.user = res.data.user;

          let role = res.data.roles[0];
          if (role == "Supervisor") {
            this.selectRole = "مشرف";
          } else if (role == "Salesman") {
            this.selectRole = "مندوب مبيعات";
          } else if (role == "Director") {
            this.selectRole = "مدير إدارة";
          } else if (role == "General Manager Of Dept") {
            this.selectRole = "رئيس قسم";
          } else if (role == "Admin") {
            this.selectRole = "أدمن";
          } else if (role == "CEO") {
            this.selectRole = "مدير عام";
          } else {
            this.selectRole = "-";
          }
        })
        .catch(() => {});
    },
    getUserInfo() {
      if (this.$route.name == "show-user") {
        this.getUser(this.$route.params.userId);
      } else {
        this.user = JSON.parse(window.localStorage.getItem("authUser"));
        this.getUser(this.user.id);
        this.role = window.localStorage.getItem("roles");
      }
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>
<style scoped>
b,
strong {
  font-size: 16px;
}
</style>
